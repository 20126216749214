import { IOrderProduct } from "../../dtos/IProduct";

export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const LOAD_PRODUCTS_SAGA = "LOAD_PRODUCTS_SAGA";

export const ADD_ORDER_PRODUCT = "ADD_ORDER_PRODUCT";
export const ADD_ORDER_PRODUCT_SAGA = "ADD_ORDER_PRODUCT_SAGA";

export const REMOVE_PRODUCTS = "REMOVE_PRODUCTS";
export const REMOVE_PRODUCTS_SAGA = "REMOVE_PRODUCTS_SAGA";

export const RESET_ORDER_ITEMS = "RESET_ORDER_ITEMS";
export const RESET_ORDER_ITEMS_SAGA = "RESET_ORDER_ITEMS_SAGA";

export const LOAD_COMMON_PRODUCTS = "LOAD_COMMON_PRODUCTS";
export const LOAD_COMMON_PRODUCTS_SAGA = "LOAD_COMMON_PRODUCTS_SAGA";

export const UPDATE_IS_LOADING_PRODUCTS = "UPDATE_IS_LOADING_PRODUCTS";

export interface ILoadProductsActionSaga {
    readonly type: typeof LOAD_PRODUCTS_SAGA;
    name: string,
    sku: string,
}
export const loadProductsPropsSaga = (name = '', sku = ''): ILoadProductsActionSaga => {
    return {
        type: LOAD_PRODUCTS_SAGA,
        name: name,
        sku: sku
    }
};

export interface ILoadCommonProductsActionSaga {
    readonly type: typeof LOAD_COMMON_PRODUCTS_SAGA;
    page: number,
    size: number,
    sort: string,
    name: string,
    sku: string,
    categories: string,
    variants: string,
    vendors: string,
    priceFrom: string,
    priceTo: string,
    token: string,
    tag?: string
}
export const loadCommonProductsPropsSaga = (page = 0, size = 20, sort = '', name = '', sku = '',
    categories = "",
    variants = "",
    vendors = "",
    priceFrom = "",
    priceTo = "",
    token: string, tag = ""): ILoadCommonProductsActionSaga => {
    return {
        type: LOAD_COMMON_PRODUCTS_SAGA,
        page: page,
        size: size,
        sort: sort,
        name: name,
        sku: sku,
        categories: categories,
        variants: variants,
        vendors: vendors,
        priceFrom: priceFrom,
        priceTo: priceTo,
        token: token,
        tag: tag
    }
};

export interface IAddSelectedItemsActionSaga {
    readonly type: typeof ADD_ORDER_PRODUCT_SAGA;
    product: IOrderProduct | null
}
export const addSelectedProductPropsSaga = (product = null): IAddSelectedItemsActionSaga => {
    return {
        type: ADD_ORDER_PRODUCT_SAGA,
        product: product,
    }
};

export interface IRemoveSelectedItemsActionSaga {
    readonly type: typeof REMOVE_PRODUCTS_SAGA;
    removableProducts: IOrderProduct[]
}
export const removeSelectedProductPropsSaga = (removableProducts: IOrderProduct[]): IRemoveSelectedItemsActionSaga => {
    return {
        type: REMOVE_PRODUCTS_SAGA,
        removableProducts: removableProducts,
    }
};
export interface IResetOrderItemsActionSaga {
    readonly type: typeof RESET_ORDER_ITEMS_SAGA;
}
export const resetOrderItemsPropsSaga = (): IResetOrderItemsActionSaga => {
    return {
        type: RESET_ORDER_ITEMS_SAGA,
    }
};
