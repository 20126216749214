import IVendor from "../../dtos/IVendor";

export const LOAD_VENDORS = "LOAD_VENDORS";
export const LOAD_VENDORS_SAGA = "LOAD_VENDORS_SAGA";

export const LOAD_VENDOR_PRODUCTS_QTYS = "LOAD_VENDOR_PRODUCTS_QTYS";
export const LOAD_VENDOR_PRODUCTS_QTYS_SAGA = "LOAD_VENDOR_PRODUCTS_QTYS_SAGA"

export const LOAD_VENDORS_PRODUCTS = "LOAD_VENDORS_PRODUCTS";
export const LOAD_VENDORS_PRODUCTS_SAGA = "LOAD_VENDORS_PRODUCTS_SAGA";

export const LOAD_VENDORS_RPODUCT_DETAILS = "LOAD_VENDORS_RPODUCT_DETAILS";
export const LOAD_VENDORS_RPODUCT_DETAILS_SAGA = "LOAD_VENDORS_RPODUCT_DETAILS_SAGA";

export const LOAD_VENDOR = "LOAD_VENDOR";
export const LOAD_VENDOR_SAGA = "LOAD_VENDOR_SAGA";

export const RESET_VENDOR_SAGA = "RESET_VENDOR_SAGA";

export const UPDATE_IS_LOADING_VENDORS = "UPDATE_IS_LOADING_VENDORS";
export const UPDATE_IS_LOADING_VENDORS_QTYS = "UPDATE_IS_LOADING_VENDORS_QTYS";

export interface ILoadVendorsActionSaga {
    [x: string]: any;
    readonly type: typeof LOAD_VENDORS_SAGA;
    lang: string,
    page: number,
    size: number,
    sort: string,
    name: string,
}
export const loadVendorsPropsSaga = (lang = '', page = 0, size = 25, sort = '', name = ''): ILoadVendorsActionSaga => {
    return {
        type: LOAD_VENDORS_SAGA,
        page: page,
        lang: lang,
        size: size,
        sort: sort,
        name: name
    }
};

export interface ILoadVendorsProductsActionSaga {
    readonly type: typeof LOAD_VENDORS_PRODUCTS_SAGA;
    page: number,
    size: number,
    sort: string,
    vendorId: string,
    name: string;
    status: string;
    qty: number,
    categories: string,
    variants: string,
    priceFrom: string,
    priceTo: string,
    product_tag?: string,
}
export const loadVendorsProductsPropsSaga = (page = 0, size = 25, sort = '', vendorId = '', name = '', status = '', qty = 10,
    categories = "",
    variants = "",
    priceFrom = "",
    priceTo = "",
    product_tag = "",
): ILoadVendorsProductsActionSaga => {
    return {
        type: LOAD_VENDORS_PRODUCTS_SAGA,
        page: page,
        size: size,
        sort: sort,
        vendorId: vendorId,
        name: name,
        status: status,
        qty: qty,
        categories: categories,
        variants: variants,
        priceFrom: priceFrom,
        priceTo: priceTo,
        product_tag
    }
};

export interface ILoadVendorsProductDetailsActionSaga {
    readonly type: typeof LOAD_VENDORS_RPODUCT_DETAILS_SAGA;
    vendorsProducts: any[]
}
export const loadVendorsProductDetailsPropsSaga = (vendorsProducts: any[]): ILoadVendorsProductDetailsActionSaga => {
    return {
        type: LOAD_VENDORS_RPODUCT_DETAILS_SAGA,
        vendorsProducts: vendorsProducts
    }
};

export interface ILoadVendorProductsQtysActionSaga {
    readonly type: typeof LOAD_VENDOR_PRODUCTS_QTYS_SAGA;
    vendors: IVendor[]
}
export const loadVendorProductsQtysPropsSaga = (vendors: IVendor[]): ILoadVendorProductsQtysActionSaga => {
    return {
        type: LOAD_VENDOR_PRODUCTS_QTYS_SAGA,
        vendors: vendors
    }
};

export interface ILoadVendorActionSaga {
    readonly type: typeof LOAD_VENDOR_SAGA;
    vendorId: string
}
export const loadVendorPropsSaga = (vendorId = ''): ILoadVendorActionSaga => {
    return {
        type: LOAD_VENDOR_SAGA,
        vendorId: vendorId,
    }
};
export interface IResetVendorActionSaga {
    readonly type: typeof RESET_VENDOR_SAGA;
}
export const resetVendorPropsSaga = (): IResetVendorActionSaga => {
    return {
        type: RESET_VENDOR_SAGA,
    }
};
