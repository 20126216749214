import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_PRODUCTS_SAGA, ILoadProductsActionSaga,
    ADD_ORDER_PRODUCT_SAGA, IAddSelectedItemsActionSaga,
    REMOVE_PRODUCTS_SAGA, IRemoveSelectedItemsActionSaga,
    RESET_ORDER_ITEMS_SAGA, IResetOrderItemsActionSaga,
    LOAD_COMMON_PRODUCTS_SAGA, ILoadCommonProductsActionSaga,
} from "./types";
import { fetchProducts, fetchCommonProducts, fetchProductVariations } from "../../api/products";
import {
    loadProductsProps, addSelectedItemsProps, removeSelectedItemsProps, resetOrderItemsProps, updateIsLoadingProductsProps,
    loadCommonProductsProps
} from "../../reducers/products/types"
import IProduct, { IOrderProduct } from '../../dtos/IProduct';
import { handlStatusCodeMessage } from "../../helpers";


function* loadProductsData(action: ILoadProductsActionSaga) {
    try {
        if (action.name.length > 2) {
            yield put(
                updateIsLoadingProductsProps({
                    isLoadingProducts: true,
                    errorMessage: undefined,
                })
            );
            const products: IOrderProduct[] = yield call(fetchProducts, action.name, action.sku);
            yield put(loadProductsProps({ products: products }))
        }
        else {
            yield put(
                updateIsLoadingProductsProps({
                    isLoadingProducts: true,
                    errorMessage: undefined,
                })
            );
            yield put(loadProductsProps({ products: [] }))
        }
        yield put(
            updateIsLoadingProductsProps({
                isLoadingProducts: false,
                errorMessage: undefined,
            })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(
            updateIsLoadingProductsProps({
                isLoadingProducts: false,
                errorMessage: undefined,
            })
        );
    }
}
export function* watchLoadProducts() {
    yield takeLatest(LOAD_PRODUCTS_SAGA, loadProductsData)
}
function* loadCommonProductsData(action: ILoadCommonProductsActionSaga) {
    try {
        yield put(updateIsLoadingProductsProps({ isLoadingProducts: true, errorMessage: undefined })
        );
        const commonProducts: { commonProducts: IProduct[], totalCommonProductsCount: number } = yield call(fetchCommonProducts, action.page, action.size, action.sort, action.name, action.sku,
            action.categories, action.variants, action.vendors, action.priceFrom, action.priceTo,
            action.token, action.tag);
        yield put(loadCommonProductsProps(commonProducts))
        yield put(updateIsLoadingProductsProps({ isLoadingProducts: false, errorMessage: undefined })
        );
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
        yield put(updateIsLoadingProductsProps({ isLoadingProducts: false, errorMessage: undefined })
        );
    }
}
export function* watchLoadCommonProducts() {
    yield takeLatest(LOAD_COMMON_PRODUCTS_SAGA, loadCommonProductsData)
}

function* addProductData(action: IAddSelectedItemsActionSaga) {
    try {
        let selectedItems: IOrderProduct[] = [];
        if (action.product != null) {
            if (action.product.product_vendors) {
                let product = {
                    ...action.product,
                    selected: false
                }
                selectedItems.push(product);
            }
            else {
                selectedItems.push(action.product);
            }
        }
        yield put(addSelectedItemsProps({ selectedItems: selectedItems }))
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchAddProductData() {
    yield takeLatest(ADD_ORDER_PRODUCT_SAGA, addProductData)
}

function* removeProductsData(action: IRemoveSelectedItemsActionSaga) {
    try {
        let removableProducts: IOrderProduct[] = action.removableProducts;
        yield put(removeSelectedItemsProps({ selectedItems: removableProducts }))
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchRemoveProductData() {
    yield takeLatest(REMOVE_PRODUCTS_SAGA, removeProductsData)
}
function* resetItemsData(action: IResetOrderItemsActionSaga) {
    try {
        let selectedItems: IOrderProduct[] = [];
        yield put(resetOrderItemsProps({ selectedItems: selectedItems }))
    } catch (error: any) {
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchResetItemsData() {
    yield takeLatest(RESET_ORDER_ITEMS_SAGA, resetItemsData)
}

export default [
    watchLoadProducts(),
    watchAddProductData(),
    watchRemoveProductData(),
    watchResetItemsData(),
    watchLoadCommonProducts(),
]