import { axiosInstance, store } from "../store";
import {
  handlStatusCodeMessage,
  jsonToFormData,
  jsonToFormData_v2,
} from "../helpers";
import IProduct from "../dtos/IProduct";
import { ILastUpdated } from "../dtos/IFDCLastUpdated";
import axios from "axios";
import { IProductTag } from "../dtos/IProductTag";

export function fetchProducts(name = "", sku = ""): Promise<IProduct[]> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}catalog/product?page=0&size=20&name=${name}&sku=${sku}&price_nullable=true&status=A&vendor_status=A&search_engine_type=elastic`
    )
    .then((res) => {
      if (res.status == 200) {
        return res.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return [];
    });
}
export function fetchAllProducts() {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}catalog/product?size=1000`)
    .then((res) => {
      if (res.status == 200) {
        return res.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return [];
    });
}
export function fetchCommonProducts(
  page = 0,
  size = 20,
  sort = "",
  name = "",
  sku = "",
  categories = "",
  variants = "",
  vendors = "",
  priceFrom = "",
  priceTo = "",
  token: string,
  product_tag: string = ""
): Promise<{ commonProducts: IProduct[]; totalCommonProductsCount: number }> {
  const languageReducer: any = store.getState().LanguageSwitcher;

  return axios
    .get(
      `${process.env.REACT_APP_API_URL
      }catalog/v3/product/products?page=${page}&size=${size}&sort=${sort}&name=${name}&sku=${sku}&${categories ? `categories=${categories}` : ""
      }&${variants ? `variants=${variants}` : ""}&${vendors ? `vendors=${vendors}` : ""
      }&price_from=${priceFrom}&price_to=${priceTo}&common_product_status=${process.env.REACT_APP_IS_VENDOR == "true" ? "A" : ""
      }&master_product_id=0&exclude_status=O&vendor_portal=${process.env.REACT_APP_IS_VENDOR
      }&search_engine_type=elastic&include_tagged=true
      &product_tag=${product_tag}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": languageReducer.language.locale,
        },
      }
    )
    .then((res) => {
      return res.status == 200
        ? {
          commonProducts: res.data,
          totalCommonProductsCount: parseInt(res.headers["x-total-count"]),
        }
        : { commonProducts: [], totalCommonProductsCount: 0 };
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return { commonProducts: [], totalCommonProductsCount: 0 };
    });
}
export function fetchVariations(productId: number): Promise<any[]> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}catalog/product/${productId}/variations`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("fetchVariations-ERR", err.response);
    });
}
export function fetchFDCHistory(): Promise<ILastUpdated[]> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}catalog/product/fdc_history`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
}

export function fetchProductById(
  page = 0,
  size = 10,
  productId: string | null = null
): Promise<{ product: IProduct | null }> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}catalog/product?page=${page}&size=${size}&id=${productId}`
    )
    .then((res) => {
      return res.status == 200 ? res.data[0] : null;
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return null;
    });
}
export function fetchProductBasicData(
  productId: string | null = null
): Promise<{ product: IProduct | null }> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}catalog/v2/product/${productId}/basic_data`
    )
    .then((res) => {
      return res.status === 200 ? res.data : null;
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return null;
    });
}
export function fetchPackageBasicData(
  productId: string | null = null
): Promise<{ product: IProduct | null }> {
  return axiosInstance.get(
    `${process.env.REACT_APP_API_URL}catalog/v2/product/${productId}/basic_data`
  );
}
export function fetchProductBasicDataImage(
  productId: string | null = null
): Promise<any> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}catalog/v2/product/${productId}/images`
    )
    .then((res) => {
      return res.status == 200 ? res.data : null;
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return null;
    });
}
export function fetchProductBasicDataCategories(
  productId: string | null = null
): Promise<any> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}catalog/v2/product/${productId}/product_categories`
    )
    .then((res) => {
      return res.status == 200 ? res.data : null;
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return null;
    });
}
export function fetchPackageBasicDataCategories(
  productId: string | null = null
): Promise<any> {
  return axiosInstance.get(
    `${process.env.REACT_APP_API_URL}catalog/v2/product/${productId}/product_categories`
  );
}
export function fetchPackageDescription(
  productId: string | null = null
): Promise<any> {
  return axiosInstance.get(
    `${process.env.REACT_APP_API_URL}catalog/package-description?product_id=${productId}`
  );
}
export function fetchProductVariations(
  productId: string | null = null
): Promise<any> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}catalog/v2/product/child-products/${productId}`
    )
    .then(async (res) => {
      if (res.status == 200) {
        res.data = res.data.filter((o: any) => {
          return o.status != "O";
        });
        res.data = await Promise.all(
          res.data.map(async (v: any) => {
            const variants: any = await fetchProductFeatureValues(v.id);
            return {
              ...v,
              variants: variants.filter((v: any) => {
                return v.feature_id != 1 && v.feature_id != 2;
              }),
            };
          })
        );
        return res.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return [];
    });
}
export function fetchProductFeatureValues(
  productId: string | null = null
): Promise<{ variations: any[] | null }> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}catalog/v2/product/${productId}/feature_values`
    )
    .then((res) => {
      return res.status == 200 ? res.data : [];
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return [];
    });
}
export function fetchProductVendors(
  productId: number,
  page = 0,
  sort = ""
): Promise<any> {
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}catalog/product/${productId}/vendor?&status=A`
    )
    .then((res) => {
      if (res.status == 200) return res.data;
    })
    .catch((err) => {
      return [];
    });
}
export function createProductBasicData(productBasicData: any): Promise<any> {
  let formData = jsonToFormData_v2(productBasicData);
  productBasicData.categories?.forEach((cat: any, index: any) => {
    formData.append(`categories[${index}]`, JSON.stringify(cat));
  });
  // productBasicData.productImages.forEach((img: any, index: any) => {
  //   formData.append(`productImages[${index}]`, img.originFileObj);
  // });
  productBasicData.productTags?.forEach((tag, index: number) => {
    formData.append(`productTags[${index}]`, tag.toString());
  });
  if (productBasicData.imageUrls?.length > 0) {
    productBasicData.imageUrls.forEach((img: any, index: any) => {
      let path = img?.image_path?.split("/public")?.[1];
      formData.append(`imagesUrl[${index}]`, path);
    });
  }
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}catalog/v2/product`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    })
    .then((res) => {
      if (res.status == 200) {
        handlStatusCodeMessage({ code: res.status, entity: "create_product" });
        return res.data;
      } else return null;
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return null;
    });
}
export function createPackage(productBasicData: any) {
  let formData = jsonToFormData_v2(productBasicData);
  productBasicData.categories?.forEach((cat: any, index: any) => {
    formData.append(`categories[${index}]`, JSON.stringify(cat));
  });
  productBasicData.productTags?.forEach((tag, index: number) => {
    formData.append(`productTags[${index}]`, tag.toString());
  });
  if (productBasicData.imageUrls?.length > 0) {
    productBasicData.imageUrls.forEach((img: any, index: any) => {
      let path = img?.image_path?.split("/public")?.[1];
      formData.append(`imagesUrl[${index}]`, path);
    });
  }
  return axiosInstance.post(
    `${process.env.REACT_APP_API_URL}catalog/v2/product`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    }
  );
}
export function createPackageDescription(packageDescription: any) {
  let formData = jsonToFormData_v2(packageDescription);
  return axiosInstance.post(
    `${process.env.REACT_APP_API_URL}catalog/package-description`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    }
  );
}
export function editPackageDescription(
  packageDescription: any,
  description_id: number,
  images_to_delete: string
) {
  let formData = jsonToFormData_v2(packageDescription);
  return axiosInstance.put(
    `${process.env.REACT_APP_API_URL}catalog/package-description/${description_id}?images_to_delete=${images_to_delete}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    }
  );
}
export function createProductVariations(
  id: any,
  productVariations: any
): Promise<any> {
  return axiosInstance
    .post(
      `${process.env.REACT_APP_API_URL}catalog/v2/product/${id}/feature_data`,
      productVariations,
      {
        headers: {
          "Accept-Language": "",
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      }
    )
    .then((res) => {
      if (res.status == 200) {
        handlStatusCodeMessage({ code: res.status, entity: "create_product" });
        return true;
      } else return false;
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return false;
    });
}
export function createCommercialData(
  id: any,
  commercialData: any
): Promise<any> {
  return axiosInstance
    .post(
      `${process.env.REACT_APP_API_URL}catalog/product-vendor/${id}/save-commerial-data`,
      { commerial_data: commercialData },
      {}
    )
    .then((res) => {
      if (res.status == 200) {
        handlStatusCodeMessage({ code: res.status, entity: "create_product" });
        return true;
      } else return false;
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return false;
    });
}
export function postInlineEdits(data: any): Promise<any> {
  return axiosInstance
    .post(
      `${process.env.REACT_APP_API_URL}catalog/v2/product/update_product_inline_actions`,
      data,
      {}
    )
    .then((res) => {
      if (res.status == 200) {
        handlStatusCodeMessage({ code: res.status, entity: "create_product" });
        return true;
      } else return false;
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return false;
    });
}
export function fetchProductTags(
  tagName = "",
  page = 0,
  size = 1000
): Promise<IProductTag[]> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}catalog/catalog_tags?page=${page}&size=${size}&name=${tagName}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        handlStatusCodeMessage({
          code: err.response?.status,
          entity: "product",
        });
      });
  });
}
export function createTag(tagName: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_URL}catalog/catalog_tags`, {
        name: tagName,
      })
      .then((res) => {
        handlStatusCodeMessage({ code: res.status, entity: "tags" });
        resolve(true);
      })
      .catch((err) => {
        handlStatusCodeMessage({
          code: err.response?.status,
          entity: "productTags",
        });
        reject();
      });
  });
}
export function postProductObsolete(productId: any): Promise<any> {
  return axiosInstance
    .post(
      `${process.env.REACT_APP_PRODUCTS_URI}/v3/product/${productId}`,
      undefined
    )
    .then((res) => {
      if (res.status == 200) {
        handlStatusCodeMessage({ code: res.status, entity: "product" });
        return true;
      } else return false;
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response?.status, entity: "product" });
      return false;
    });
}
